import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import Icon from '../../Icon'
import socialIconsEmail from '../../../constants/social-icons-email'
import legalLinks from '../../../constants/legal-links'

interface Props {
  copyright: JSX.Element
}

const FooterWrapper = styled.footer`
  ${tw`flex justify-center`}
  @media (max-width: 600px) {
    width: 600px;
  }

  > div {
    ${tw`grid grid-cols-2 bg-black text-white p-8`}
    max-width: 600px;

    .logo,
    .social {
      ${tw`mb-8`}
    }

    .social {
      ${tw`justify-end`}

      ul {
        ${tw`items-center`}
      }
    }

    .legal {
      ${tw`mb-8`}
      li {
        ${tw`inline-block`}

        a {
          ${tw`text-white leading-none mr-6`}
        }
      }
    }

    .legal,
    .disclaimer,
    .copyright {
      ${tw`col-start-1 col-end-3 text-center`}
    }

    .legal a,
    .disclaimer,
    .copyright p {
      ${tw`text-base`}
    }
  }
`

function Footer({ copyright }: Props): JSX.Element {
  return (
    <>
      <FooterWrapper>
        <div className="border-t-bby grid">
          <div className="logo">
            <img
              src="//images.ctfassets.net/orf4d9wgx51u/6HYbEF3hHATC2a863ZzGZO/3f433984b958964f44547c618e0d7d9d/email-footer-lively-bbyh-logo-23.png"
              alt="logo"
            />
          </div>
          <div className="social">
            <ul className="flex space-x-6">
              {socialIconsEmail.map((item, index) => {
                return (
                  <li key={index}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={`navigate to ${item.text}`}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <Icon icon={item.icon} color="#fff" size="1.5rem" />
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
          <ul className="legal">
            {legalLinks.map((item, index) => {
              return (
                <li key={index}>
                  <Link to={`${item.url}`}>{item.text}</Link>
                </li>
              )
            })}
          </ul>
          {copyright}
          <div className="disclaimer">
            Note that you may continue to receive transactional or relationship
            emails so long as you have an account with us. For more information,
            please visit our Privacy Policy.
          </div>
        </div>
      </FooterWrapper>
    </>
  )
}

export default Footer
