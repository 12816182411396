export default [
  {
    text: 'Facebook',
    icon: 'facebook-3',
    url: 'https://www.facebook.com/LivelySoc',
  },
  {
    text: 'Instagram',
    icon: 'instagram-2',
    url: 'https://www.instagram.com/LivelySocial',
  },
  {
    text: 'Youtube',
    icon: 'youtube-2',
    url: 'https://www.youtube.com/lively',
  },
  {
    text: 'Pinterest',
    icon: 'pinterest-2',
    url: 'https://www.pinterest.com/LivelySocial',
  },
]
