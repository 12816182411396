export default [
  {
    text: 'States Rights Privacy Policy',
    url: '/legal/states-rights-privacy-policy',
  },
  {
    text: 'Do Not Sell My Personal Information',
    url: '/legal/do-not-sell-my-personal-information',
  },
  {
    text: 'Consumer Health Data Privacy Statement',
    url: '/legal/consumer-health-data-privacy-statement',
  },
  {
    text: 'Privacy Policy',
    url: '/legal/privacy-policy',
  },
  {
    text: 'Terms of Use',
    url: '/legal/terms-of-use',
  },
  {
    text: 'Legal',
    url: '/legal/',
  },
]
