import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import {
  ContentfulPage,
  ContentfulMedia,
  ContentfulText,
} from '../../../types/graphql-types'
import SEO from '../../components/SEO'
import LayoutBlank from '../../components/Layout/Blank'
import Footer from '../../components/Footer/Email'

interface Props {
  data: {
    contentfulPage: ContentfulPage
  }
}

export const query = graphql`
  query LivelyWearablePackageCancellationConfirmationPage {
    contentfulPage(
      slug: { eq: "/emails/lively-wearable-package-cancellation-confirmation/" }
      type: { eq: "Email" }
    ) {
      title
      externalName
      slug
      seoTitle
      seoDescription
      type
      gtmPageType
      references {
        ... on ContentfulMedia {
          __typename
          id
          name
          externalName
          altText
          media {
            file {
              url
            }
          }
        }
        ... on ContentfulText {
          __typename
          id
          name
          externalName
          longSimpleText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

const ContentWrapper = styled.article`
  ${tw`my-0 mx-auto`}
  width: 600px;

  .hero {
    ${tw`mb-4`}
  }
`

const LivelyWearablePackageCancellationConfirmation: React.FC<Props> = ({
  data,
}: Props) => {
  return (
    <>
      <LayoutBlank>
        <SEO
          title={data.contentfulPage.seoTitle as string}
          description={data.contentfulPage.seoDescription as string}
          noIndex={true}
        />
        <ContentWrapper>
          {data.contentfulPage.references?.map(content => {
            const media = content as ContentfulMedia
            const copy = content as ContentfulText

            if (media.externalName === 'Brand Logo') {
              return (
                <header key={media.id}>
                  <img
                    className="h-auto"
                    src={media.media?.file?.url as string}
                    alt={media.altText as string}
                  />
                </header>
              )
            }
            if (media.externalName === 'Email Hero') {
              return (
                <section key={media.id} className="hero">
                  <h1>
                    <img
                      className="h-auto"
                      src={media.media?.file?.url as string}
                      alt={media.altText as string}
                    />
                  </h1>
                </section>
              )
            }
            if (copy.name === 'Cancellation Web View Body') {
              return (
                <section key={copy.id}>
                  <div
                    key={copy.id}
                    dangerouslySetInnerHTML={{
                      __html: copy.longSimpleText?.childMarkdownRemark
                        ?.html as string,
                    }}
                  ></div>
                </section>
              )
            }
          })}
        </ContentWrapper>
      </LayoutBlank>
      {data.contentfulPage.references?.map(content => {
        const footerCopy = content as ContentfulText

        if (footerCopy.externalName === 'Copyright') {
          const copyrightText = (
            <div
              className="copyright mb-0"
              key={footerCopy.id}
              dangerouslySetInnerHTML={{
                __html: footerCopy.longSimpleText?.childMarkdownRemark
                  ?.html as string,
              }}
            ></div>
          )
          return <Footer key={footerCopy.id} copyright={copyrightText} />
        }
      })}
    </>
  )
}

export default LivelyWearablePackageCancellationConfirmation
